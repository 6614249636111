
@use "../../colors.scss";

.menus {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 100vw;
  background-color: colors.$lighter-green;
}
.profile-wrapper {
  width: 50vw;
  margin: 12vh 0 5vh 0;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;

  .account-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 1rem;

    .profile-section {
      flex: 1 1;
      text-align: left;

      h2 {
        font-size: 1.5rem;
        margin-top: 10px;
      }

      p {
        margin-top: 5px;
        font-size: 1rem;
        color: #555;
      }

      .avatar-container {
        position: relative;
        display: inline-block;

        .avatar {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          object-fit: cover;
          transition: opacity 0.3s ease-in-out;
      }
      #instructor-label {
        display: none;
        color: #333;
    }
    }
    }
    .userbox {
      display: flex;
      flex-direction: column;
      padding-top: 20px;

      #type {
        width: max-content;
        text-align: center;
        background-color: #0388A6;
        color: white;
        padding: 12px;
        border-radius: 5px;
    }
  }
}

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    padding: 0 0 35px 0;
    border-bottom: 1px solid #e9e9e9;

    .buttons-container {
      display: flex;
      gap: 1rem;
    }

    .buttons {
      display: flex;
      .edit-button{
        font-size: 1.2rem;
        width: 11rem;
        height: 100%;
        background-color: transparent;
        margin: 3px;
        padding: 2px 10px;
        border: 1px solid rgba(204, 204, 204, 0.6117647059);
        border-radius: 3px;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        color: #0388A6;
        background-size: contain;
        background-repeat: no-repeat;
        font-weight: bold;
        transition: 300ms ease;
        img{
          width: 30px;
        }
      }
    }

    a.perfil {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #00739a;
      color: #fff ;
      padding: 2px 3rem;
      border-radius: 5px;
    }
    a.senha {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #e9e9e9 ;
      color: #767c88 ;
      background-color: #fff ;
      padding: 2px 3rem;
      border-radius: 5px;
    }
  }
}
.profile-info-wrapper {
  border: 1px solid #e9e9e9;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-top: 35px;

  form {
    padding: 30px 30px 30px 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .field {
      display: flex;
      flex-direction: column;
      gap: 5px;
      width: 100%;

      .number{
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 1rem;

        #numcode{
          width: 100%;
        }

        #number{
          width: 100%;
        }
      }
      
    }

    div {
      p {
        color: #767c88;
        font-family: "ClearSans";
        font-size: 1rem;
        margin-bottom: 4px;
      }
      input {
        font-size: medium;
      }
      input:disabled {
        cursor: default;
        background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
        color: light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
        border-color: rgba(118, 118, 118, 0.3);
      }
      input::placeholder {
        color: #3e3e3e;
        font-size: medium;
        font-family: "ClearSans";
      }
    }
    .adress-fields {
      display: flex;
      width: 100%;
      gap: 20px;
    }
    button {
      max-width: fit-content;
      padding: 10px 28px;
      font-family: "ClearSans";
      background-color: #00739a;
      margin-top: 40px;
    }
  }
}

@media (max-width: 1919px) {
    .profile-wrapper{
      width: 60vw;
      margin: 18vh 0 5vh 0;
    }
}

@media (max-width: 900px) {
  .profile-wrapper{
    width: 80vw;
    margin: 11vh 0 5vh 0;
  }
}

@media (max-width: 599px) {
  .profile-wrapper{
    width: 90vw;
    margin: 18vh 0 5vh 0;
    padding: 10px;

    .account-info{
      .profile-section {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
    .userbox{
      padding: 0;
    }
  }

    .buttons-wrapper {
      flex-direction: column;
      height: 20vh;

        .buttons-container {
          height: 5vh;
          justify-content: space-between;

        }
        .buttons {
          width: 100%;
          justify-content: center;
        }
    }
  }

  .profile-info-wrapper form {
    padding: 10px 10px;
  }
}
