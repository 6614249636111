@use '../../colors.scss';
$default_height: max-content;
$expanded1: 200px;
$expanded2: 300px;

.mainPopup {
    padding: auto;
    text-align: center;
    background-color: white;
    cursor: pointer;

    .pre-content {
        z-index: 1;
        opacity: 0;
        padding: 2px;
        border-radius: 4%;
        height: 0;
        position: absolute;
        right: 4.4%;
        background-color: colors.$base-white;
        transition: all 300ms ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: baseline;

        span {
            font-size: small;
        }
    }

    .content {
        z-index: 12;
        opacity: 0;
        padding: 12px;
        border-radius: 4%;
        height: 0;
        position: absolute;
        right: 2%;
        background-color: colors.$base-white;
        transition: all 300ms ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: baseline;
    }

    .item {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
    }

    .link {
        font-size: large;
        border-radius: 2%;

        &:hover {
            background-color: colors.$ocean-blue;
            color:colors.$base-white;
        }
    }

    .name {
        font-size: large;
        opacity: 0.7;
    }

    #avatar {
        opacity: 1;
        height: 70px;
        border-radius: 50%;

        &:hover+.pre-visible {
            height: $default_height;
            opacity: 1;
        }
    }

    .content-visible {
        height: $default_height;
        opacity: 1;
    }
}