.mais-procurados-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:2.5rem;
    padding: 4rem 0;

    h1{
        font-size: 30px;
    }

    .swiper{
        margin: 0;
        width: 100%;
        max-width: 85vw;
    }

    .swiper-wrapper{
        display: flex;
        padding: 10px 0;
        box-sizing: border-box;
        justify-content: start;
    }
    
    .swiper-slide { 
        max-width: 100%;
        display: flex;
        justify-content: center;
        align-self: stretch;
        width: fit-content!important; 
    }
}

@media (max-width: 1919px) {
    .mais-procurados-section{
        h1{
            font-size: 22px;
        }
    }
}

@media (max-width: 900px) {
    .mais-procurados-section{
        h1{
            font-size: 18px;
        }
        .result-card{
           width: 100%;
        }
    }
}

@media (max-width: 599px) {
    .mais-procurados-section{
        padding: 0 0 2rem 0 ;
        h1{
            font-size: 16px;
            text-align: center;
        }

        .result-card{
            align-self: stretch;
            margin: 0 2rem;
            width: 100%;
        }
    }

}