.element {
    padding: 10px;
    padding-top: 100px;

}
.box{

    h2 {
        font-family: "Poppins";
        font-size: 28px;
        font-weight: 700;
        line-height: 50px;
        color: #3e3e3e;
    }
    h3 {
        font-family: "Poppins";
        font-size: 18px;
        font-weight: 700;
        line-height: 50px;
        color: #3e3e3e;
    }
}


.media {
    padding: 100px 0;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-self: flex-start;
    align-items: center;
    justify-items: normal;

    h2 {
        text-align: center;
        width: 100%;
        max-width: 100%;
        font-size:24px;
    }
    h3{
        text-align: center;
    }

    figure {
        background-size: contain;
        background-repeat: no-repeat;
        width: 100px;
        height: 100px;
        transition:100ms;
        margin-top:100px;
    }
    
    figure:hover {
        cursor: pointer;
        filter:opacity(80%)
    }
    
    #team {
        background-image: url("../../misc/avatar-group.svg");
        max-width: 150px;
        width: 50%;

    }

    #whatsapp{
        background-image: url('../../misc/icons/whatsapp.png');
    }
}