
@use "../../../../colors.scss";
.parceiros-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;

    padding: 4rem;
    text-align: center;
    background-color: #F3FAF9;

    h1{
        font-size: 30px;
        color: colors.$blue-marsocial;
    }

    p{
        font-size: 1rem;
        line-height: 1.75rem;
        font-weight: 300;
    }

    .parceiros-container{
        align-self: stretch;
        display: flex;
        justify-content: space-around;

        .parceiro{
            width: 100%;
            max-width: 12rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
        }
    }
}

@media (max-width: 1919px){
    .parceiros-section{
        h1{
            font-size: 22px;
        }

        p{
            font-size: 14px;
        }
    }
}

@media (max-width: 1200px){
    .parceiros-section{
        h1{
            font-size: 20px;
        }
    }
}

@media (max-width: 900px) {
    .parceiros-section{
        h1{
            font-size: 18px;
        }
    }
}

@media (max-width: 599px) {
    .parceiros-section{
        padding: 4rem 5%;
        .parceiros-container{
            flex-direction: column;
            align-items: center;
            gap: 2rem;
        }

        h1{
            font-size: 16px;
        }
    }
}