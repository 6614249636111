@use '../../../colors.scss';

.sobre{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;

    background-color: colors.$base-white;
    padding: 2% 5% 5% 5%;

    h1{
        color: colors.$ocean-blue;
        font-size: 30px;
    }

    .content{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2.5rem;
        margin-top: 2%;

        .text-container{
            grid-column: 1/2;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 2.5rem;

            p{
                text-align: center;
                font-size: 1rem;
                line-height: 1.75rem;
                font-weight: 300;
            }
        }

        .images-container{
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }

        .images{
            display: flex;
            gap: 1rem;
        }
    }
}

@media (max-width: 1919px) {
    .sobre{
        h1{
            font-size: 22px;
        }

        .content{
            margin: 0;
            display: flex;

            .text-container{
                justify-content: center;
                p{
                    font-size: 14px;
                    line-height: 1.2rem;
                    font-weight: 500;
                }
            }
        }

        .images-container{
            align-items: center;
            width: 50%;
        }

        .images-um{
            align-items: end;
        }

        .images-dois{
            align-items: start;
        }
        

        img{
            height: 70%;
            object-fit: contain;
        }


    }
}

@media (max-width: 1200px) {
    .sobre{
        h1{
            font-size: 20px;
        }
    }
}

@media (max-width: 900px) {
    .sobre{
        width: 100vw;

        h1{
            font-size: 18px;
        }

        .content{
            width: 100vw;
            padding: 0 5%;
            flex-direction: column;
            align-items: center;
        }

        .images-container{
            position: relative;
            top: -50px
        }
    }
}

@media (max-width: 599px) {
    .sobre{
        h1{
            margin-top: 2rem;
            font-size: 14px;
        }

        .content{
            height: 130vh;
            .text-container{
                p{
                    font-size: 13px;
                }
            }
            .images-container{
                top: -150px;
                gap: 0.5rem;
            }
            .images {
                gap: 0.5rem;
            }
        }

        img{
            height: 45%;
        }
    }

    
}