@use "../../colors.scss";
$border-radius: 5px;

.language-select {
  position: relative;
  width: 160px;

  .selected-option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;

    .flag-icon {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    &:hover{
      display: flex;
      background-color: #00000014;
      span{
        color:black
      }
      img{
        filter: opacity(100);
      }
    }
  }

  .options-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #eeeeee9c;
    border-radius: 1px;
    list-style: none;
    padding: 0;
    margin: 0;
    display: none;
    z-index: 1;


    li {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      transition: 700ms;

      &:hover {
        background-color: colors.$ocean-blue;
      }

      .flag-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }

  &:hover .options-list {
    display: none;
    /*Remoção de Display de idiomas
     display: block;
    */
  }
}

.custom-select {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  border-radius: 1px;
  background-color: colors.$base-white;
  transition: border-color 0.3s;

  .selected-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 1.2rem;
    border-radius: $border-radius;

    .icon {
      height: 20px;
      width: 20px;
      margin-left: 10px;
      transition: filter 0.3s;
    }

    .options-list {
      display: block;
    }

    &:hover {
      border-color: colors.$ocean-blue;
      background-color: colors.$ocean-blue;

      span {
        color: colors.$base-white;
      }

      .icon {
        filter: brightness(0) invert(1);
      }
    }
  }

  .options-list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: $border-radius;
    background-color: colors.$base-white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;

    .option {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 1rem;
      cursor: pointer;
      transition: background-color 0.3s;
      border-radius: $border-radius;

      &:hover {
        background-color: colors.$ocean-blue;
      }

      &.selected {
        background-color: colors.$ocean-blue;
        color: colors.$base-white;

        .option-icon {
          filter: brightness(0) invert(1);
        }
      }

      .option-icon {
        height: 20px;
        width: 20px;
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }

  &:hover .options-list {
    display: flex;
    flex-direction: column;

    li:hover {
      span {
        color: colors.$base-white
      }

      .option-icon {
        filter: brightness(0) invert(1);
      }
    }
  }
}

@media (max-width: 1200px) {
  .language-select{
    width: 130px;
  }
  span{
    font-size: 0.8rem;
  }
}

@media (max-width: 599px) {
  .span-lang{
    display: none;
  }

  .language-select{
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flag-selected{
    margin-left: 10px;
  }
}

@media (max-width: 1919px) {
  .custom-select .selected-option {
    font-size: 16px;
  }

  .custom-select .options-list .option {
    font-size: 16px;
  }
}