@use "../../../colors.scss";
@use "../../../icons.scss";
@use "../../../placeholders.scss";

$border-radius: 5px;

.placeholder {
    background-color: colors.$base-white;
    width: 100%;
    min-height: 24rem;
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: start;
    justify-content: start;
    color: colors.$black;
    background-size: cover;
    transition: box-shadow 0.3s;
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    padding: 30px;
    margin-top: 2rem;

    .local{
        width: 49%;

        .area-local{
            width: 100%;

            select{
                width: 100%;
                padding: 1rem;               
                }
            }
    }


    //Corrigir responsividade
    .type {
        position: absolute;
        top: 29%;
        left: 54.5%;
        text-align: start;
        z-index: 1;
        width: fit-content;

        h2 {
            font-size: 1.2rem;
            margin-bottom: 1rem;
            color: colors.$black;
        }

        .selector {
            border: 1px solid colors.$black;
            border-radius: $border-radius;
            padding: 0.5rem;
            width: max-content;
            background-color: colors.$base-white;
            transition: border-color 0.3s;

            &:focus-within {
                border-color: colors.$ocean-blue;
            }
        }
    }

    .main-content {
        flex: 1;
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 1.5rem;

    }

    .main-content,
    .optional {
        .title-area {
            font-size: 1.2rem;
            margin: 1.5rem 0;
            color: #767c88;
        }
        .content-area{
            display: flex;
            gap: 1rem;
            width: 100%;
            .area { 
                width: 100%;

                input {
                    width: 100%;
                }
            }
        }
        .area-2{
            width: 49%;
        }
        .area-3{
            width: 100%;
        }

        .area {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            

            span {
                color: #767c88;
                font-family: "ClearSans";
                font-size: 1rem;
                margin-bottom: 4px;
            }

            input,
            textarea {
                padding: 1rem;
                border-color: rgba(118, 118, 118, 0.3);
                font-size: medium;
                background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
                width: 100%;
                height: 100%;

                min-width: 300px;
                transition: border-color 0.3s;
                accent-color: colors.$ocean-blue;
                resize: none;
                &:focus {
                    border-color: colors.$ocean-blue;
                    outline: none;
                }

            }

            .number {
                display: flex;
                flex-direction: row;
                justify-content: left;

                #numcode {
                    min-width: 53px;
                    text-align: center;
                    margin-right: 10px;
                }

                #number {
                    width: 248px;
                }
            }
        }
    }

    .optional {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1.5rem;
        width: 100%;
        align-items: start;



        .checkbox-group {
            padding-top: 0px;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            span {
                font-weight: 500;
                font-size: 1rem;
                color: #767c88;
            }

            .input {
                display: flex;
                flex-direction: row;
                justify-content: start;
                align-items: center;
                gap: 0.5rem;

                label{
                    color: #777777;
                    font-weight: 500;
                    font-size: medium;
                }
                input {
                    width: 15px;
                    height: 15px;
                    justify-content: center;
                    border-radius: 12px;
                    accent-color: #0388A6;
                    transition: 300ms;
                }
            }
        }

        .title{
            font-weight: 500;
            font-size: 1.3rem;
            color: #767c88;
        }

        .instructor {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
            gap: 1.5rem;
            width: 100%;
            textarea{
                resize: none;
                height: 100%;
            }
            .user-instructor {
                display: flex;
                flex-direction: row;
                align-items: center; 
                padding-bottom: 1rem;
                gap: 0.5rem;

                input {
                    width: 15px;
                    height: 15px;
                    justify-content: center;
                    border-radius: 12px;
                    accent-color: colors.$ocean-blue;
                    transition: 300ms;

                    &:checked+span {
                        color: colors.$black
                    }
                }

                span {
                    color: colors.$medium-grey;
                    font-size: medium;
                }
            }

            input {
                width: 100%;
                text-align: start ! important;
                border: 1px solid #ccc;
                border-radius: $border-radius;

                &:focus {
                    border-color: colors.$ocean-blue;
                    outline: none;
                }
            }
        }
    }
    .bottom{
        width: 100%;
        display:flex;
        flex-direction:row;
        justify-content: center;
        gap: 1rem;
        button{
            background-color: colors.$ocean-blue;
            padding: 1rem 1.5rem;
        }
        
    }
}
#none-place{
    padding-top:25%
}

.title {
    font-weight: bold;

}

.terms{
    font-size: large;
    display: flex;
    align-items: center;
    gap: .5rem;
    input {
        width: 15px;
        height: 15px;
        justify-content: center;
        border-radius: 12px;
        accent-color: #0388A6;
        transition: 300ms;
    }

    label{
        color: #777777;
        font-weight: 500;
        font-size: medium;
    }

    .link {
        text-decoration: underline;
        color: colors.$ocean-blue;
    }
}

@media (max-width: 1919px) {
    .placeholder .main-content .area input, .placeholder .main-content .area textarea, .placeholder .optional .area input, .placeholder .optional .area textarea {
        font-size: 16px;
    }
    .placeholder .local .area-local select {
        font-size: 16px;
    }
}

@media (max-width: 900px) {
    .placeholder .main-content .area .number #number, .placeholder .optional .area .number #number {
        min-width: 200px;
    }
    
}

@media (max-width: 599px) {
    .placeholder .main-content .area textarea{
        min-width: 100%;
    }
    .placeholder .optional .area {
        width: 100%;
    }
    .placeholder .optional .area textarea{
        min-width: 100%;
    }
    .placeholder .optional .content-area {
        flex-direction: column;
    }
    .placeholder .main-content .area .number, .placeholder .optional .area .number {
        display: flex;
        // flex-direction: column;
        gap: 0.5rem;
        justify-content: space-between;

        #numcode {
            width: 20%;
        }

        #number {
            width: 75%;
            min-width: 75%;
        }
    }
    .placeholder .optional .content-area .area input {
        min-width: 100%;
    }
}