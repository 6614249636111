.security-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  
    h1 {
      font-size: 2em;
      color: #333;
      margin-bottom: 20px;
    }
  
    h2 {
      font-size: 1.5em;
      color: #555;
      margin-top: 20px;
    }
  
    p {
      font-size: 1em;
      color: #666;
      margin-bottom: 15px;
    }
  
    ul {
      margin-left: 20px;
      color: #444;
  
      li {
        margin-bottom: 10px;
      }
    }
  
    a {
      color: #0066cc;
      text-decoration: none;
  
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .end{
    padding-bottom:180px;
  }