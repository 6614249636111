@use '../../../colors.scss';
$border: 20px;

.introduction {
  display: flex;
  flex-direction: column;
  background: url('../../../misc/introduction-background.jpg') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100%;
  height: 90vh;
  justify-content: center;

  .form {
    justify-self: center;
  }
}

.introduction-text{
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;

  h2{
    text-align: center;
    font-size: 30px;
    font-weight: normal;
    color: colors.$ocean-blue;
    font-weight:  bold;
  }
}

.sliderContainer {
  width: 35%;
  overflow: hidden;
  position: relative;
  align-self: flex-end;
  margin-right: 7%;
  height: 280px;
  color: colors.$base-white;
  padding: 1%;
  background-color: #00000070;
  border-radius: 10px;
}

.elementos {
  h2 {
    text-align: center;
    font-size: xx-large;
    font-weight: normal;
  }

  display: flex;
  flex-direction:column;
  justify-content: center;
  justify-content: space-evenly;
}

.text {
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  position: relative;
  width: 100%;
  padding-top: 40px;
  transition: opacity 0.5s ease-in-out;
}

.exit {
  opacity: 0;
}

.enter {
  opacity: 1;
}

@media (max-width: 1919px) {
  .introduction-text{
    h2{
      font-size: 22px;
    }
  }

  .sliderContainer {
    width: 37%;
    height: 220px;
    margin-top: 5%;
    padding: 1% 5%;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: start;
  }

  .elementos {
    h2 {
      text-align: center;
      font-size: 26px;
    }
  }

  .text {
    font-size: 18px;
  }
}

@media (max-width: 1200px) {

  .introduction-text{
    h2{
      font-size: 20px;
    }
  }

  .elementos {
    h2 {
      font-size: 24px;
    }
  }

  .text {
    font-size: 16px;
  }

  .sliderContainer {
    padding: 1% 3%;
  }
}

@media (max-width: 900px) {
  .introduction{
    width: 100vw;
    -webkit-background-size: cover;
  }

  .introduction-text{
    width: 100vw;
    h2{
      font-size: 18px;
    }
  }

  .sliderContainer {
    width: 40%;
    height: 200px;
    margin-top: 5%;
    padding: 1% 3%;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: start;
  }

  .elementos {
    
    h2 {
      font-size: 18px;
    }
  }

  .text {
    font-size: 14px;
    text-align: center;
  }
}

@media (max-width: 599px) {
  .introduction{
    height: 83vh;
    -webkit-background-size: cover;
  }

  .introduction-text{
    h2{
      padding: 0 5%;
      font-size: 14px;
    }
  }

  .sliderContainer {
    margin-top: 30%;
    margin-left: 10%;
    align-self: auto;
    width: 80%;
    height: 185px;
    padding: 1% 3%;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: start;
    background-color: #000000a1;
  }

  .elementos {
    
    h2 {
      font-size: 16px;
    }
  }

  .text {
    font-size: 12px;
    text-align: center;
  }
}