.avaliacoes-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    padding: 4rem;

    h1{
        font-size: 30px;
    }
    
    .avaliacoes-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 4rem;
        column-gap: 2.5rem;
    }
}

@media (max-width: 1919px) {
    .avaliacoes-section h1{
        font-size: 22px;
    }
}

@media (max-width: 900px) {
    .avaliacoes-section{
        padding: 2rem;

        h1{
            font-size: 18px;
        }

        .avaliacoes-container{
            row-gap: 2rem;
            column-gap: 1rem;
        }
    }
}

@media (max-width: 599px) {
    .avaliacoes-section{
        padding: 2rem 2rem;
        gap: 2rem;
    }
}