@use '../../colors.scss';

.confirm {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    background-color: colors.$base-white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    padding: 20px;
    width: 90%;
    max-width: 400px;
    text-align: center;
    font-family: 'Arial', sans-serif;
  
    p {
      font-size: 16px;
      color: #333;
      margin-bottom: 20px;
      line-height: 1.5;
    }
  
    button {
      display: inline-block;
      padding: 10px 20px;
      font-size: 14px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s ease, color 0.3s ease;
  
      &.link {
        background-color: colors.$ocean-blue;
        color: #fff;
        margin-right: 10px;
  
        &:hover {
          background-color: colors.$ocean-blue;
        }
      }
  
      &.close {
        background-color: colors.$base-white;
        color: #333;
  
        &:hover {
          background-color: colors.$base-white;
        }
      }
    }
  }
