@use "../../../../colors.scss";

.conheca-historias-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    padding: 4rem 5rem;
    background: var(--bg-gradient, linear-gradient(90deg, colors.$yellow-marsocial 0%, colors.$blue-agua-marsocial 85.00%, colors.$blue-marsocial 100%));
    

    .historias-container{
        display: flex;
        gap: 4rem;
        width: 100%;
        justify-content: center;
    }

    h1{
        color: colors.$base-white;
        font-size: 30px;
    }

    .swiper{
        margin: 0;
        width: 100%;
        max-width: 90vw;
    }

    .swiper-wrapper{
        display: flex;
        padding: 10px 0;
        box-sizing: border-box;
    }
    
    .swiper-slide { 
        display: flex;
        align-self: stretch;
        width: fit-content!important; 
    }
}

@media (max-width: 1919px){
    .conheca-historias-section{
        h1{
            font-size: 22px;
        }
    }
}

@media (max-width: 1200px){
    .conheca-historias-section{
        h1{
            font-size: 20px;
        }
    }
}

@media (max-width: 900px){
    .conheca-historias-section{
        h1{
            font-size: 18px;
        }
    }   
}

@media (max-width: 599px) {
    .conheca-historias-section{
        padding: 4rem 2rem;

        h1{
            text-align: center;
            font-size: 16px;
            font-weight: 500;
        }
        
        .historia-card{
            margin: 0 2rem; 
        }
    }
}