* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
.first-section {
  padding: 132px 0 391px 128px;
  background-image: url("../../../public/cadastroBg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  img {
    margin-bottom: 200px;
  }
  h1 {
    width: 100%;
    max-width: 467px;
    font-family: "Poppins";
    font-size: 48px;
    font-weight: 700;
    line-height: 50px;
    color: #3e3e3e;
  }
  p {
    width: 100%;
    max-width: 467px;
    font-family: "Poppins";
    font-size: 16px;
    margin-top: 10px;
    line-height: initial;
    color: #3e3e3e;
  }
  .cadastrar-barco {
    padding: 16px 27px;
    border: 2px solid #00739a;
    background-color: #fff;
    display: block;
    max-width: fit-content;
    margin-top: 40px;
    border-radius: 1000px;
    -webkit-border-radius: 1000px;
    -moz-border-radius: 1000px;
    -ms-border-radius: 1000px;
    -o-border-radius: 1000px;
    position: relative;
    overflow: hidden;
    z-index: 10;

    p {
      font-size: 16px;
      font-weight: 700;
      color: #3e3e3e;
      margin: 0;
      position: relative;
      z-index: 99;
      font-family: "Poppins";
    }
  }
  .cadastrar-barco:hover p {
    color: #fff;
  }
  .cadastrar-barco::before {
    content: "";
    display: block;
    width: 0%;
    background-color: #00739a;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    z-index: 1;
  }
  .cadastrar-barco:hover::before {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .first-section {
    background-image: url("../../../public/backmb.png");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 0 100px 0;
    text-align: center;
    h1 {
      max-width: 80%;
      margin: 100px auto 20px auto;
      font-size: 32px;
      line-height: initial;
    }
    p {
      margin: 0 auto;
    }
    a {
      margin: 0 auto;
    }
  }
 
}
.popup {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
  background-color: #fff;
  text-align: center;
  box-shadow: 0px 0px 36px -8px rgba(0, 0, 0, 0.38);
  border-radius: 10px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -400px);
  top: 20px;
  transition: transform 0.3s ease;

  p {
    font-family: "Poppins";
    font-size: 16px;
    line-height: initial;
    color: green;
    font-weight: 700;
  }
  .redirect {
    margin-top: 10px;
    color: #3e3e3e;
  }
}
.popup.active {
  transform: translate(-50%, 0);
}
