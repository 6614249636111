@use "../../colors.scss";

.card-contato{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    background-color: colors.$base-white;
    padding: 1.5rem;
    border-radius: 0.625rem;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 3rem;

        .avaliacoes{
            display: flex;
            gap: 0.5rem;
        }
    }

    .info{
        display: flex;
        flex-direction: column;
        gap: 1rem;

        div{
            display: flex;
            justify-content: space-between;
        }
    }

    .acao{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .line{
        height: 2px;
        width: 100%;
        background-color: colors.$grey;
    }
}

@media (max-width: 900px) {
    .card-contato{
       margin-top : 1rem;

       span{
           display: flex;
           align-items: center;
       }
    }
}