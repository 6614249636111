@use "../../colors.scss";

.card-reserva{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    padding: 1.5rem;
    border-radius: 0.625rem;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: colors.$base-white;

    .header{
        align-self: self-start;
        display: flex;
        align-items: center;
        gap: 3rem;

        .destaque{
            font-size: 1.5rem;

            b{
                color: colors.$black;
            }
        }

        .avaliacoes{
            display: flex;
            gap: 0.5rem;
        }
    }

    .inputs-container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-self: stretch;

        border-radius: 0.625rem;
        border: 1px solid #777;

        
        box-sizing: border-box;

        .input{
            display: flex;
            flex-direction: column;
            padding: 1rem;
            gap: 0.3rem;

            label{
                text-transform: uppercase;
            }
        }

        .input#inicio{
            grid-column: 1/2;
            border-right: 1px solid #777;

        }

        .input#final{
            grid-column: 2/3;
        }

        .input#quantidade-pessoas{
            grid-column: 1/3;
            border-top: 1px solid #777;
            align-items: flex-start;
        }
    }

    button.blue-button{
        width: 100%;
    }

    .checkout-info {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap:1.5rem;
        
        .info{
            align-self: stretch;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap:1rem;

            div{
                display: flex;
                justify-content: space-between;
            }
        }

        .line{
            width: 100%;
            height: 0.0625rem;
            background-color: colors.$grey;
        }
    }
}

@media (max-width: 1919px) {
    .card-reserva{
        .header{
            .destaque{
                font-size: 16px;
                b{
                    font-size: 20px;
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .card-reserva{
        margin-top: 1rem;
    }
    
}

@media (max-width: 599px) {
    .card-reserva{
        font-size: 0.85rem;

        .inputs-container{    
            .input{
                padding: 0.5rem;
                label{
                    font-size: 0.85rem;
                }
            }
        }

        .header{
            flex-direction: column;
            gap: 1rem;
            width: 100%;
            align-items: flex-start;

            .avaliacoes{
                width: 100%;
                align-self: flex-start;

                
            span{
                display: flex;

            }
            }
            .destaque{
                font-size: 1.2rem;
            }

        }

        span{
            display: block;
        }
    }
}