@use "../../colors.scss";

.form{
    width: 100%;
    padding: 30px 30px 30px 15px;
    border: 1px solid #e9e9e9;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .divField{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    h3{
        color: #767c88;
        margin-bottom: 15px;
        font-size: 1rem;
        margin-bottom: 4px;
    }

    label{
        color: #767c88;
        font-family: "ClearSans";
        font-size: 1rem;
        margin-bottom: 4px;
    }

    input{
        padding: 11px 18px;
        border: 1px solid #e9e9e9;
        border-radius: 10px;
        border-color: #0388A6;
        width: 100%;
        font-size: medium;
    }

    input:disabled {
        cursor: default;
        background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
        color: light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
        border-color: rgba(118, 118, 118, 0.3);
    }

    .field-group{
        display: flex;
        gap: 1rem;
    }

    .field-group div{
        flex: 1;
    }

    .field-groups{
        display: flex;
        justify-content: space-between;
        gap: 1rem;
    }

    #about{
        resize: none;
        height: 100px;
        width: 100%;
        border-color: rgba(118, 118, 118, 0.3);
        border-radius: 10px;
        padding: 11px 18px;
    }

    #divNumcode{
        width: 25%;
    }

    .languages-edit{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border : 1px solid #e9e9e9;
        border-radius: 10px;
        padding: 10px;
    }

    .language-checkbox{
        display: flex;
        justify-content: start;
        align-items: center;
        gap: 1rem;

        input{
            display: none;
        }

        label {
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            padding: 1rem;
        }

        input[type="checkbox"]+label::before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            border: 2px solid #ccc;
            border-radius: 4px;
            background-color: white;
            transition: background-color 0.3s, border-color 0.3s;
        }

        input[type="checkbox"]:checked+label::before {
            background-color: colors.$ocean-blue;
            border-color: colors.$ocean-blue;
        }

        input[type="checkbox"]+label::after {
            content: '';
            position: absolute;
            left: 6px;
            top: 2px;
            font-size: 20px;
            text-align: center;
            color: white;
            transition: opacity 0.2s;
            opacity: 0;
            /* Initially hidden */
            padding: 1rem;
        }

        input[type="checkbox"]:checked+label::after {
            content: '\2713';
            /* Checkmark symbol */
            opacity: 1;
            /* Show when checked */
        }
    
        #otherLabel{
            margin: 0;
            width: 100%;
        }

        #otherInput{
            display: initial;
            width: 30%;
            border-radius: 2px;
        }
        
    }

    .languages-grid{
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        
        .language-box{
            background-color: colors.$ocean-blue;
            color: colors.$base-white;
            padding: 0.8rem 1rem;
            border-radius: 10px;
        }
    }

}
