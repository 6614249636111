.loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 25vw;
}

.loading{
    width: 25vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5rem;

    h1{
        position: absolute;
        font-size: 30px;
        text-align: center;
        margin-bottom: 19vw;
    }
}

@media (max-width: 1919px) {
    .loading h1{
            font-size: 22px;
        }
}

@media (max-width: 900px) {
    .loading-container{
        height: 75vw;
    }
    .loading{
        width: 50vw;
         h1{
            font-size: 18px;
        }
    }
}

@media (max-width: 599px) {
    .loading-container{
        height: 50vh;
    }
    .loading h1{
        font-size: 16px;
        margin-bottom: 55vw;
    }
    .loading{
        width: 75vw;
    }
}