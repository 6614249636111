@use "../../colors.scss";
@use "../../placeholders.scss";

.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    max-height: 1000px;
    min-height:700px;
    background-color: colors.$base-white;
    padding: 0.5rem;
    border: 1px solid #cccccc38;
    border-radius: 12px;
    
    align-items: center;

    background-image:placeholders.$not-found;
    background-repeat:none;
    background-size:cover;

    h2{
        text-align:center;
        filter:opacity(0.9);
        border-radius: 12px;
        color:colors.$black;
        padding:7%;
        padding-top:1px;
    }
    .turnback{
            color:colors.$base-white;
            padding:10px;
            border-radius:12px;
            background-color: colors.$ocean-red;
            transition:300ms;
            &:hover{
                transform:scale(1.1)
            }
        
    }
}