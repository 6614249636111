@use '../../colors.scss';

.busca-section{
    display: grid;
    grid-template-rows: auto auto auto;
    margin-top: 10vh;

    div{
        box-sizing: border-box;
    }

    form{
        height: 25vh;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: colors.$verde-agua-light;
        padding: 2rem 10rem;
        
        .left-container, .right-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            width: 100%;
            #search-button{
                transition:300ms ease-in;
                width: 50%;
                background-color: colors.$ocean-blue;
                &:hover{
                    opacity: 0.8;
                }
            }
        }

        .input-container{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            width: 50%;
        }

        .checkbox-container{
            gap: 1rem;
            justify-content: space-between;
            width: 50%;
            
            .filter{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
                width: 33%;
                background-color: colors.$base-white;
                padding:8px;
                border-radius: 6px;
                input{
                    accent-color: colors.$ocean-blue;
                }
            }
        }
    }
}

@media (max-width: 1919px) {
    .busca-section{
        margin-top: 14vh;
        form{
            height: 35vh;
            padding: 2rem 5rem;

            .input-container{
                width: 70%;
            }

            .checkbox-container{
                width: 70%;
            }

            .left-container, .right-container{
                gap: 0.5rem;

                #search-button{
                    width: 70%;
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .busca-section{
        margin-top: 8vh;
        form{
            padding: 2rem 2rem;
            height: auto;

            .input-container{
                width: 90%;
            }

            .checkbox-container{
                width: 90%;
            }

            .left-container, .right-container{
                gap: 0.5rem;

                #search-button{
                    width: 90%;
                }
            }
        }
    }
}

@media (max-width: 599px) {
    .busca-section{
        form{
            margin-top: 8vh;
            display: flex;
            flex-direction: column;
            padding: 1.5rem;

            .checkbox-container{
                flex-direction: column;
                align-items: center;
                gap: 0.5rem;
                width: 100%;

                .filter{
                    width: 90%;
                    justify-content: start;
                }
            }
        }
    }
}