@use '../../colors.scss';
.login-popup {
    position: fixed;
    width: 100%;
    max-width: 480px;
    background-color: #fff;
    left: 50%;
    top: 50%;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    z-index: 10;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;

    img {
      opacity: 1;
    }

    .logo-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }

    .login-title {
      font-family: "ClearSansLight";
      font-size: 30px;
      text-align: center;
      margin-bottom: 20px;
    }

    .login-buttons {
      display: flex;
      align-content: center;
      justify-content: center;
      gap: 8px;
      margin: 20px 0;

      button {
        padding: 14px;
        border: 1px solid #bebebe;
        font-family: "ClearSansLight";
        font-size: 16px;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
      }
    }

    .input-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;

      input {
        padding: 15px;
        border: 1px solid #bebebe;
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
      }
    }

    .esqueceu-senha {
      display: block;
      width: 100%;
      text-align: right;
      padding: 0;
      text-decoration: underline;
      color: #000;
      margin: 20px 0;
    }

    label {
      font-family: "ClearSansLight";
      font-size: 16px;
      color: #777777;
    }

    .button-login {
      width: 100%;
      padding: 15px;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
      background-color: #60c0bf;
      font-family: "ClearSansLight";
      font-size: 16px;
      color: #fff;
      margin: 20px 0;
    }

    .register-wrapper p {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        font-family: "ClearSansLight";
        font-size: 16px;
        color: #333333;
        text-align: center;
      }

      button {
        text-decoration: underline;
        color: #000;
        background-color: #fff;
      }
    }
  }

  .background {
    background-color: #000;
    opacity: 0.3;
    width: 100%;
    height: auto;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  .auth {
    margin-top:32px;  
    margin-bottom: 100px;
    padding: 14px;
    display: flex;
    flex-direction: row;
    justify-content: center; // Ensures horizontal centering
    align-items: center; // Ensures vertical centering
    height: 40px;
    width: 100%;
  
    .login-icon {
      transition: 100ms ease-in-out;
  
      span {
        text-align: center;
        display: flex;
        flex-direction: column;
        margin-top: 15px;
      }
  
      figure {
        display: flex;
        flex-direction: row;
        background-size: cover;
        background-repeat: no-repeat;
        height: 50px;
        width: 50px;
        margin: 0 auto;
        padding-bottom: 41px;
      }
  
      &:hover {
        cursor: pointer;
        filter: opacity(40%);
      }
    }
  }
  

  .logout-icon {
    height: 100px;
    width: 100px;

    .google-signout {
      color:colors.$ocean-red;
      height: 30px;
      width: 30px;
    }

  }
  .error{
    padding:12px;
    text-align: center;  
    font-weight:bold;
    color:colors.$ocean-red
  }

  .toggle-category {
    display: flex;
    justify-content: center;
    button {
      padding: 10px 20px;
      margin: 0 10px;
      cursor: pointer;
      background-color: #ddd;
      border: 1px solid #ccc;
      border-radius: 5px;
      transition:100ms ease-in-out;
      &:hover{
        opacity: 80%  ;
      }

      &.active {
        background-color: colors.$ocean-blue;
        color: white;
      }
  }
}

@media (max-width: 1919px) {
  .login-popup {
    gap: 8px;
    max-height: 98vh;
    max-width: 430px;

   .login-title{
    font-size: 20px;
   }

   .logo-wrapper{
    margin-bottom: 0;
    img{
      width: 70px;
      height: 70px;
    }
   }

   .login-title{
    margin-bottom: 0px;
   }

   .input-wrapper{
    gap: 5px;
   }
  }
}