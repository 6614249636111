.marsocial-home-section{
    .sobre{
        margin-top: 6vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4rem 4rem 4 4rem;
        text-align: center;
        gap: 1.5rem !important;

        .header{
            background-image: url("../../../../misc/placeholder/pescadorMarAberto.jpg");
            width: 100vw;
            height: 25vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 1rem;
        }

        h2{
            font-size: 30px;
        }
    
        .content{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            margin-top: 3rem;
    
            .text-container{
                grid-column: 1/3;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
            }
    
            .image-container{
                display: flex;
                justify-content: center;
            }
    
            img{
                width: 30rem;
            }
        }
    }
}

@media (max-width: 1919px){
    .marsocial-home-section{
        .sobre{
         margin-top: 10vh;

         h2{
             font-size: 22px;
         }

         .content{
            .image-container{
                align-items: center;
            }
            img{
                width: 29rem;
            }
         }
        }
}
}
@media (max-width: 1200px){
    .marsocial-home-section{
        .sobre{
            margin-top: 14vh;

            h2{
                font-size: 20px;
            }
    }
    }
}
@media (max-width: 900px){
    .marsocial-home-section{
        .sobre{
            margin-top: 7vh;

            h2{
                font-size: 18px;
            }

            .content{
                display: flex;
                flex-direction: column;

                img{
                    width: 25rem;
                }
            }

        }
    }
}
@media (max-width: 599px){
    .header{
        background-position: center center;
        background-size: cover;
    }
    .marsocial-home-section{
        margin-top: 6rem;
        .sobre{
            h2{
                font-size: 14px;
            }

            .content{
                margin-top: 1rem;
                .image-container{
                    align-items: start;
                }
                img{
                    width: 20rem;
                }
            }
        }
    }
}