@use "../../colors.scss";

.result-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    width: 18.7vw;
    border-radius: 0.625rem;
    box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.25);

    img{
        width: 100%;
        height: 100%;
        max-height: 200px;
        border-radius: 0.625rem;
    }

    .content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;

        height: 100%;
        padding: 1.5rem 1rem;

        .info{
            display: flex;
            flex-direction: column;
            gap: 2rem;

            .description{
                max-height: 15vh;
                overflow: scroll;
                overflow-x: hidden;
            }
        }

        .header{
            display: flex;
            justify-content: space-between;

            .rating{
                display: flex;
                align-items: center;
                gap: 0.5rem;

                img{
                    min-width: 10px;
                }
            }
        }

        .action{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    a{
        padding: 1rem;
        border-radius: 0.625rem;

        background-color: colors.$verde-agua;
        color: colors.$base-white;
    }
}

@media (max-width: 1919px) {
    .result-card{
        max-width: 17.9vw;
        height: auto;

        img{
            max-height: 150px;
        }

        .content{
            padding: 1rem;

            .info{
                gap: 1rem;

                .description{
                    max-height: 20vh;
                }
            }
        }

        a{
            padding: .8rem 1rem;
        }
    }
}

@media (max-width: 900px) {
    .result-card{
        max-width: 38vw;
        height: auto;

        img{
            max-height: 150px;
        }

        .content{
            padding: 1rem;

            .info{
                gap: 1rem;

                .description{
                    max-height: 15vh;
                }
            }
        }

        a{
            padding: .8rem 1rem;
        }
    }
}

@media (max-width: 599px) {
    .result-card{
        max-width: 100vw;
        height: auto;

        img{
            max-height: 150px;
        }

        .content{
            padding: 1rem;

            .info{
                gap: 1rem;

                .description{
                    max-height: 20vh;
                }
            }
        }

        a{
            padding: .8rem 1rem;
        }
    }
}