.App{
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
}
.page {
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 1rem;
  
    @media (min-width: 768px) {
      grid-template-columns: 1fr 3fr;
      grid-template-rows: auto 1fr;
      gap: 1.5rem;
    }
  
    @media (min-width: 1024px) {
      grid-template-columns: 1fr 4fr;
      gap: 2rem;
    }
  }
  