@use "../../colors.scss";
@use '../../icons.scss';

.image-input-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .image-upload-label {
        display: inline-block;
        background-color: colors.$ocean-blue;
        color: colors.$base-white;
        padding: 1rem 1.5rem;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        transition: background-color 0.3s ease;
        width: fit-content;
        align-self: center;

        &:hover {
            background-color: colors.$verde-agua;
        }

        input[type="file"] {
            display: none;
        }

        span {
            color: colors.$base-white
        }
    }

    .image-preview-list {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        border-style: solid;
        border-width: 2px;
        border-color: colors.$border;
        border-radius: 12px;

        .image-preview-item {
            position: relative;
            width: 100px;
            height: 100px;
            overflow: hidden;
            border: 1px solid colors.$ocean-blue;
            border-radius: 8px;

            .image-preview {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .delete-button {
                position: absolute;
                top: 5px;
                right: 5px;
                background-color: rgba(0, 0, 0, 0.6);
                color: colors.$base-white;
                border: none;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1rem;
                transition: background-color 0.2s ease;

                &:hover {
                    background-color: rgba(255, 0, 0, 0.7);
                }
            }
        }
    }
}

.avatar-container {
    position: absolute;
    display: inline-block;
    #instructor-label{
        display: none;
        color:colors.$black
    }

    .avatar {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        object-fit: cover;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    }

    .change-avatar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        opacity: 0;

        .upload-label {
            cursor: pointer;
        }

        input[type='file'] {
            display: block;
            opacity: 0;
        }

        &:hover {
            opacity: 1;
            background-image: icons.$upload-image;
            background-size: 30%;
            background-repeat: no-repeat;
            background-position: center;
        }

    }
}
#instructor-avatar{
    position:absolute;
    right:34%;
    margin-top: 1.5rem;
    #instructor-label{
        display: block;
        margin-top: 1rem;
    }
}

@media (max-width: 1919px) {
    #instructor-avatar{
        right:30%;
    }
}

@media (max-width: 900px) {
    #instructor-avatar{
        right:20%;
    }
    
}

@media (max-width: 599px) {
    #instructor-avatar {
        position: relative;
        right: 0;
        margin-top: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
    }
}