@use "../../../colors.scss";

button{
    border: none;
    background-color: colors.$ocean-blue;
    color: white;
    padding: .5rem 3rem;
    border-radius: 5px;
    cursor: pointer;
}

button:hover{
    background-color: colors.$verde-agua;
}