.avaliacao{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 30rem;
    padding: 1rem;
    border-radius: 0.625rem;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .header{
        display: flex;
        gap: 0.5rem;
        
        img{
            width: 100%;
            max-height: 4rem;
            max-width: 4rem;
            border-radius: 50%;
        }

        .info{
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0.3rem;
        }
    }
}

@media (max-width: 900px) {
    .avaliacao{
        max-width: 40rem;

        span {
            display: block;
        }
    }
}