@use "../../../../colors.scss";

.maraberto-experiencia-section{
    display: flex;
    flex-direction: column;
    background-color: colors.$base-white;
    padding: 4rem 5% 0 5%;
    gap: 3rem;

    &>header{
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
        align-items: flex-start;
        font-family: ClearSansLight!important;
        font-weight: 300;

        h2{
            color: colors.$ocean-blue;
            font-size: 2rem;
        }

        .info{
            display: flex;
            gap: 1rem;
            align-items: center;
            width: 100vw;

            .rating{
                display: flex;
                gap: 0.1rem;
            }
        }
    }

    &>.image-gallery{
        display: flex;
        gap: 2rem;

        .gallery {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            width: 10%;

            img{
                cursor: pointer;
                width: 100%;
                height: 100%;
                border-radius: 5px;
                box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
                object-fit: cover;
            }
            
            img:hover{
                transform: scale(1.05);
            }
        }

        .main-img{
            width: 50%;
            height: 100%;
            border-radius: 0.625rem;
            box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
            object-fit: cover;
        }

        .side-container{
            display: flex;
            width: 50%;
            gap: 1rem;

            p{
                font-size: 20px;
                line-height: 1.5rem;
                font-weight: 300;
                font-family: ClearSansLight;
            }
        }
    }

    &>.main-experiencia{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap:2rem;

        &>header{
            grid-column: 1/3;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 1.5rem;

            .head{
                display: flex;
                gap: 1rem;

                .capacidade{
                    color: black;
                    font-weight: 600;
                    font-family: ClearSansLight;
                }
            }
            
            .headings{
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                h2{
                    color: colors.$ocean-blue;
                    font-size: 1.5rem;
                }
            }

            img{
                width: 150px;
                height: 150px;
                border-radius: 50%;
            }
        }
            .destaques-container{
                display: flex;
                flex-direction: column;
                gap: 2rem;
            }
    }

}

@media (max-width: 1919px) {
    .experiencia-section{
        header{
            h2{
                font-size: 24px;
            }

            img{
                max-width: 100px;
                max-height: 100px;
            }
        }

        .image-gallery{
            .side-container{
                p{
                    font-size: 16px;
                }
            }
        }

        .main-experiencia{
            header{
                justify-content: space-around;

                .headings{
                    h2{
                        font-size: 22px;
                    }
                }

                .head{
                    .capacidade{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .maraberto-experiencia-section > .main-experiencia > header img {
        width: 100px;
        height: 100px;
    }
    
    .maraberto-experiencia-section {
        padding: 1rem 5%;
        gap: 2rem;

        .image-gallery {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: auto auto;
            grid-template-areas:
                "gallery main-img main-img"
                "side-container side-container side-container";

            .gallery {
                width: 15vh;
                grid-area: gallery;
            }

            .main-img {
                width: 65vw;
                grid-area: main-img;
            }

            .side-container {
                grid-area: side-container;
                width: 100%;
                p {
                    font-size: 14px;
                }
            }
        }

        .main-experiencia {
            grid-template-columns: 1fr;
            gap: 0rem;
        }
    }
    .maraberto-experiencia-section header h2 {
        font-size: 18px;
    }
}


@media (max-width:599px) {
    .maraberto-experiencia-section > .main-experiencia > header img {
        width: 100px;
        height: 100px;
    }

    .maraberto-experiencia-section{
        width: 100vw;
        padding: 4rem 5% 0 5%;
        gap: 1rem;

        header{
            h2{
                font-size: 18px;
            }

            span{
                display: flex;
            }
        }
        

        .image-gallery{
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .gallery{
                width: 100%;
                display: flex;
                flex-direction: row;
                gap: 0.5rem;
                justify-content: space-between;

                img{
                    width: 22%;
                    height: 50px;
                }
            }

            .main-img{
                width: 100%;
            }
        }


        header{
            gap: 0.5rem;
            .info{
                gap:0.3rem;
            }
        }

        .main{
            .info{
                display: flex;
                flex-direction: column;
            }
            header{
                gap: 1rem;

                img{
                    max-width: 50px ;
                }
            }
        }

        .rodal{
            overflow: auto;
            
            .rodal-dialog{
                position: absolute;
                margin: 1rem 1rem;
            }
        }

    }
}