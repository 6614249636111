@use "../../colors.scss";
@use "../../icons.scss";
$anchor: url("../../misc/placeholder/anchor.jpg");
@use "../../placeholders.scss";

.loading{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    p{
        color: colors.$ocean-blue;
        font-weight: bold;
        font-size: 32px;

        @media (max-width: 1919px) {
            font-size: 24px;
        }

        @media (max-width: 900px) {
            font-size: 20px;
        }
    }
}

.selector{
    h2{
        font-size: 1.3rem;
    }
}
.announcement-pages {
    min-height: 100vh;
    background-color: colors.$lighter-green;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    input{
        background-color: colors.$base-white;
    }
    .no-announcements {
        min-height: 100vh;
        width: 100vw;
        border-radius: 12px;
        position: relative;
        background: rgba(255, 255, 255, 0.8);
        overflow: hidden;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        @media (min-width: 768px) {
            background-size: contain;
        }
        @media (min-width: 1024px) {
            background-size: contain;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: $anchor;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0.3;
            z-index: 1;
        }

        .content {
            color: colors.$black;
            z-index: 2;
            padding: 2rem;
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;
            gap: 1rem;

            .title {
                font-size: 32px;
                color: colors.$ocean-blue;
            
            }

            .content-element{
                margin: 2rem;
                font-size: 22px;
                color: colors.$ocean-blue;
            }

        }
    }
}

.advice {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    color: #555;

    .event-button {
        padding: 12px;
        height: 4rem;
        width: 4rem;
        max-height: 100%;
        max-width: 100%;
        background-color: white;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: icons.$plus;
        cursor: pointer;
        transition: transform 0.2s, box-shadow 0.2s;
        #plus{background-image:icons.$plus}
        #previous{background-image:icons}

        &:hover {
            transform: scale(1.1);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        }
    
    button {
        all: unset;
        cursor: pointer;
        color: colors.$base-white;
    }
}
}

@media (max-width: 1919px) {

    .announcement-pages .announcement-form {
        max-width: 900px;
        margin-top: 6.5rem;
    }

    .selector{
        h2{
            font-size: 22px;
        }
    }

    .announcement-pages .no-announcements .content .title {
        font-size: 24px;
    }

    .announcement-pages .no-announcements .content .content-element {
        font-size: 18px;
    }
} 

@media (max-width: 900px) {
    .announcement-pages .announcement-form {
        max-width: 90vw;
        margin-top: 6rem;
    }
    .announcement-pages .no-announcements .content .title {
        font-size: 20px;
    }

    .announcement-pages .no-announcements .content .content-element {
        font-size: 16px;
    }
}

@media (max-width: 599px) {

    .announcement-pages .announcement-form {
        min-width: 70vw;
        margin-top: 7.5rem;
    }

    .selector{
        h2{
            font-size: 22px;
        }
    }

    .announcement-pages .no-announcements .content .title {
        font-size: 20px;
    }

    .announcement-pages .no-announcements .content span {
        display: block;
        font-size: 14px;
    }
}

