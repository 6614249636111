@use "../../../colors.scss";

.card-destaque{
    display: flex;
    gap: 1.5rem;
    padding: 1rem 1.5rem;
    background: colors.$base-white;
    border-radius: 0.625rem;
    box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .icon{
        width: 100%;
        max-width: 3rem;
        max-height: 3rem;
    }

    .info{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}