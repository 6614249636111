.nav {
  font-size: 24px;
  font-family: "Poppins";
  color: #3e3e3e;
  padding: 32px 35px;
  border-bottom: 1px solid #e9e9e9;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .perfil-buttons {
    display: flex;
    align-items: center;
    gap: 1.25rem;
  }

  .notifications {
    position: relative;
    width: fit-content;

    .notification-number {
      font-size: 10px;
      width: 15px;
      height: 15px;
      background-color: #006d94;
      color: #fff;
      border-radius: 1000px;
      -webkit-border-radius: 1000px;
      -moz-border-radius: 1000px;
      -ms-border-radius: 1000px;
      -o-border-radius: 1000px;
      text-align: center;
      position: absolute;
      right: -7px;
      top: -8px;
      border: 3px solid #fff;
    }
  }

  .profile-main-button {
    display: flex;
    align-items: center;
    gap: 0.625rem;

    .user-info {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      :first-child {
        color: #000;
      }
      :nth-child(2) {
        color: #767c88;
      }
    }
  }
}
.profile-picture img {
  opacity: 1 !important;
}
