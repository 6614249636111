
//tipos
$dive: url("./misc/placeholder/dive.png");
$boat: url("./misc/placeholder/boat.png");
$swim: url("./misc/placeholder/swim.png");
$surf: url("./misc/placeholder/surf.png");
$dinghy: url("./misc/placeholder/dinghy.png");
$sail: url("./misc/placeholder/sail.png");
$none:url("./misc/placeholder/alter-place.png");
$not-found:url('./misc/placeholder/not-found.png');
$anchor:url('./misc/placeholder/anchor.jpg');

#anchor{
    background-color:$anchor
}
#not-found{
    background-image:$not-found;
}
#none-place{
    background-image: $none;
}
#dive-place{
    background-image: $dive;
}

#boat-place {
    background-image: $boat;
}

#swim-place {
    background-image: $swim;
}

#surf-place {
    background-image: $surf;
}

#dinghy-place {
    background-image: $dinghy;
}

#sail-place {
    background-image: $sail;
}
