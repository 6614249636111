@use '../../colors.scss';



.faq-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4rem;
    margin-top: 2rem;
    padding: 6rem 2rem;

    header {
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
    }

    .perguntas-container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .pergunta {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            max-width: 60rem;

            div {
                font-weight: 400;
            }

            .paragraph {
                display: flex;
                flex-direction: column;
                gap: 2rem;
            }
        }

        .line {
            width: 100%;
            height: 1px;
            background-color: colors.$grey;
        }
    }

    .ainda-tem-perguntas-container {
        display: flex;

        flex-direction: column;
        align-self: stretch;
        align-items: center;
        gap: 2rem;

        padding: 2rem;

        background: var(--gray-50, #F9FAFB);

        button {
            padding: 0.6rem 1.2rem;
            color: colors.$base-white;
            background-color: colors.$verde-agua;
            border-radius: 0.5rem;
        }
    }
}
.box-container {
    display: flex;
    gap: 2rem;
    width: 100%;
    justify-content: center;
}
.box {
    width: 100%;
    h1 {
        text-align: center;
        width: 100%;
        max-width: 100%;
        font-family: "Poppins";
        font-size: 38px;
        font-weight: 700;
        line-height: 50px;
        color: #3e3e3e;
    }

    div {
        div {
            h2 {
                width: 100%;
                max-width: 100%;
                font-family: "Poppins";
                font-size: 28px;
                font-weight: 700;
                line-height: 50px;
                color: #3e3e3e;
            }
        }
    }
}

.contact-form {
    margin-top: 20px;
    margin-bottom: 250px;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin : 0 auto;

    .email-form {
        width: 100%;
        max-width: 1000px !important;
        margin: 0 auto;
        padding: 100px 100px;
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        display: flex;
        align-items: start;
        justify-content: center;
        flex-direction: column;

        form {
            margin-top: 40px;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 20px;
            width: 100%;

            .input-wrapper {
                width: 100%;

                p {
                    font-family: "Poppins";
                    font-size: 16px;
                    line-height: initial;
                    color: #3e3e3e;
                    margin-bottom: 4px;
                }

                input {
                    border: 1px solid #0000002f;
                    height: 55px;
                    width: 100%;
                    font-family: "Poppins";
                    padding: 0 20px;
                    border-radius: 10px;
                    -webkit-border-radius: 10px;
                    -moz-border-radius: 10px;
                    -ms-border-radius: 10px;
                    -o-border-radius: 10px;
                    background-color: #fff;
                }

                textarea {
                    border: 1px solid #0000002f;
                    height: 145px;
                    width: 100%;
                    font-family: "Poppins";
                    padding: 20px;
                    border-radius: 10px;
                    -webkit-border-radius: 10px;
                    -moz-border-radius: 10px;
                    -ms-border-radius: 10px;
                    -o-border-radius: 10px;
                    outline: none;
                }
            }

            button {
                padding: 17px 114px;
                background-color: #00739a;
                display: block;
                max-width: fit-content;
                margin-top: 40px;
                border-radius: 1000px;
                -webkit-border-radius: 1000px;
                -moz-border-radius: 1000px;
                -ms-border-radius: 1000px;
                -o-border-radius: 1000px;
                font-family: "Poppins";
                font-size: 16px;
                font-weight: 700;
                line-height: initial;
                color: #fff;
            }
        }
    }

    .separator-form {
        max-width: 100px;
        width: 30%;
        text-align: center;
        margin: 0 auto;
        padding-top: 100px;
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        align-items: center;
        justify-content: center;

    }

    .media-form {
        padding: 50px 0;
        width: 100%;
        max-width: 600px !important;
        padding: 50px 0;
        width: 100%;
        margin: 0 auto;
        padding: 100px 100px;
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        align-items: start;
        justify-content: start;
    }

}

@media (max-width:1024px) {
    .box-container{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .contact-form {
        background-image: none;
        text-align: center;
        margin: 0;

        h1 {
            max-width: 80%;
            margin: 100px auto 20px auto;
            position: initial !important;
            font-size: 32px !important;
            line-height: initial !important;
        }

        form {
            margin: 0 auto;

            button {
                width: 100% !important;
                max-width: 100%;
            }
        }
    }

    .input-wrapper {
        p {
            text-align: left;
        }
    }
}
.box{
    display: flex;
    flex-direction:row;
}

@media (max-width: 599px) {
    .email-form {
        padding: 0 !important;
    }
}