@use "../../colors.scss";

footer{
    display: grid;
    grid-template-rows: auto;

    background-color: colors.$ocean-blue;
    color: colors.$base-white;
    padding: 4rem 5rem;
    max-height: 250px;
    z-index: 100;
    .logo{
        grid-row: 1;
        max-width: 3.5rem;
    }

    .contact-info{
        grid-row: 1;
        width: max-content;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-self: center;
        text-align: center;
    }
}

#contact{
    position:fixed;
    right:2%;
    bottom:20%;
    display:flex;
    flex-direction:row;
    align-items: center;
    z-index: 1;
    transition:600ms;
    #whatsapp{
        height: 4rem;
        width: 4rem;
        max-height: 100%;
        max-width: 100%;
        background-image: url("../../misc/icons/whatsapp.png");
        background-size: contain;
        background-repeat: no-repeat;
    }
    &:hover{
        filter:opacity(0.7)
    }
}

@media (max-width: 1919px) {
    .contact-info{
        p{
            font-size: 14px;
        }
    }
}

@media (max-width: 599px) {
    footer{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        padding: 2rem 1rem;
        .contact-info{
            p{
                font-size: 12px;
            }
        }
    }
}