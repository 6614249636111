$base-white: #FFF;
$verde-agua:#60C0BF;
$verde-agua-deep:#33838C;
$verde-agua-light: #BADAD9;
$lighter-green: #DEF2F1;
$blue: #049DBF;
$ocean-blue: #0388A6;
$dark-deep-green: #07474F;  
$black: #333;
$grey: #F2F2F2;
$yellow-sand: #F2D1B2;
$medium-grey: #777777;
$ocean-red:#aa1c1a;
$yellow-marsocial: #e3c53f;
$blue-marsocial: #006D94;
$blue-agua-marsocial: #00ADD4;
$border:#77777744;

.white{
    color: $base-white;
}

.black{
    color: $black;
}

.verde-agua{
    color: $verde-agua;
}

.verde-agua-deep{
    color: $verde-agua-deep;
}

.verde-agua-light{
    color: $verde-agua-light;
}

.lighter-green{
    color: $lighter-green;
}

.blue{
    color: $blue;
}

.ocean-blue{
    color: $ocean-blue;
}

.dark-deep-green{
    color: $dark-deep-green;
}

.medium-grey{
    color: #777777
}
.ocean-red{
    color:$ocean-red;
}

