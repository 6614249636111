@use "../../colors.scss";

.historia-card{
    width: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    background-color: colors.$base-white;
    border-radius: 5px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    img{
        height: 150px;
        width: 100%;
        border-radius: 5px 5px 0 0;
    }

    .content{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;
        min-height: 9.75rem;

        padding: 1rem;

        .info{
            align-self: self-start;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }

        a{
            align-self: self-start;
            padding: 1rem;
            border-radius: 5px;
            color: colors.$base-white;
            background-color: colors.$yellow-marsocial;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

            &:hover{
                background-color: colors.$blue-agua-marsocial;
            }
        }

    }
}