.result-container{
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    padding: 2.5rem 4rem;

    .results{
        display: flex;
        flex-wrap: wrap;

        align-content: space-around;

        column-gap: 3rem;
        row-gap: 4rem;
    }
}
.result-erro{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    flex-direction: column;
    padding-top: 5vh;
    h1{
        font-size: 30px;
    }
}
.result-container-erro{
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    width: 30vw;
}

@media (max-width: 1919px) {
    .result-erro h1{
        font-size: 22px;
    }
    .result-container-erro{
        width: 29vw;
    }
}

@media (max-width: 900px) {
    .result-erro{
        height: 60vh;
    }
    .result-erro h1{
        font-size: 18px;
    }
    .result-container-erro{
        width: 60vw;
    }
}

@media (max-width: 599px) {
    .result-erro{
        height: 50vh;
        justify-content: start;
    }
    .result-erro h1{
        font-size: 16px;
    }
    .result-container-erro{
        width: 80vw;
    }
}