//medias
$instagram: url('./misc/icons/instagram.png');
$facebook: url('./misc/icons/facebook.png');
$x: url('./misc/icons/x.png');
$whatsapp: url('./misc/icons/whatsapp.png');
$google: url('./misc/icons/google.png');
$apple: url('./misc/icons/apple-logo.png');

//botões
$plus: url("./misc/icons/plus.png");
$previous: url("./misc/icons/previous.png");
$arrow-down: url('../public/icons/arrow.png');
$upload-image:url('../public/icons/upload.png');
//tipos
$dive: url("../public/icons/dive.svg");
$boat: url("../public/icons/boat.svg");
$swim: url("../public/icons/swim.png");
$surf: url("../public/icons/surf.png");
$dinghy: url("../public/icons/dinghy.svg");
$sail: url("../public/icons/sail.svg");



#dive {
    background-image: $dive;
}

#arrow-down {
    background-image: $arrow-down
}

#boat {
    background-image: $boat;
}

#swim {
    background-image: $swim;
}

#surf {
    background-image: $surf;
}

#dinghy {
    background-image: $dinghy;
}

#sail {
    background-image: $sail;
}

#plus {
    background-image: $plus;
}

#google {
    background-image: $google;
}

#apple {
    background-image: $apple;
}

#whatsapp {
    background-image: $whatsapp;
}

#x {
    background-image: $x;
}

#facebook {
    background-image: $facebook
}

#instagram {
    background-image: $instagram;
}

#previous {
    background-image: $previous;
}

#upload{
    background-image:$upload-image
}