$upload-image: url('../../misc/icons/upload.png');
@use "../../colors.scss";

.account-container{
    background-color: colors.$lighter-green;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 7rem 0 4rem 0;
}
.account-menu {
    min-width: 50vw;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: start;
        margin-bottom: 20px;
        height: 5vh;
        .buttons{
            display:flex;
            flex-direction:column;
            justify-content: space-between;
        }

        h1 {
            font-size: 1.8rem;
            color: #333;
        }

        .edit-button {
            font-size: 1.2rem;
            width: 11rem;
            height: 100%;
            background-color: transparent;
            margin: 3px;
            padding: 2px 10px;
            border: 1px solid #cccccc9c;
            border-radius: 3px;
            background-color: transparent;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            color: colors.$ocean-blue;
            background-size: contain;
            background-repeat: no-repeat;
            font-weight: bold;
            transition: 300ms ease;

            &:hover {
                text-decoration: underline;
                background-color: rgba(128, 128, 128, 0.247);
            }
        }

        #save, #edit, #reset{
            img{
                width: 30px;
            }
        }
    }
    
    h3 {
        margin-top: 12px;
    }
    .account-info {

        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        .profile-section {
            flex: 1;
            text-align: left;

            img {
                border-radius: 50%;
                width: 100px;
                height: 100px;
                object-fit: cover;
            }

            h2 {
                font-size: 1.5rem;
                margin-top: 10px;
            }

            p {
                margin-top: 5px;
                font-size: 1rem;
                color: #555;
            }

            .avatar-container {
                position: relative;
                display: inline-block;

                .avatar {
                    width: 120px;
                    height: 120px;
                    border-radius: 50%;
                    object-fit: cover;
                    transition: opacity 0.3s ease-in-out;
                }

                .change-avatar {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;

                    .upload-label {
                        color: white;
                        cursor: pointer;
                        font-size: 24px;
                    }
                }

                &:hover .change-avatar {
                    opacity: 1;
                    background-image: $upload-image;
                    background-size: 30%;
                    background-repeat: no-repeat;
                    background-position: center;

                }
            }
        }
        .userbox{
            display:flex;
            flex-direction:column;
            padding-top:20px;
            #type{
                width: max-content;
                text-align: center;
                background-color: colors.$ocean-blue;
                color:white;
                padding:12px;
                border-radius:5px;
            }
        }
    }
    .account-settings {
        margin-top: 30px;
        border: 1px solid #e9e9e9;
        border-radius: 10px;
        padding: 30px 30px 30px 15px;

        h3 {
            color: #767c88;
            margin-bottom: 15px;
            font-size: 1rem;
            margin-bottom: 4px;
        }

        .account-info {
            border-style: solid;
        }

        .text {
            margin-top: 12px;
            margin-bottom: 5px;
            border-color: rgba(118, 118, 118, 0.3);
            text-align: left;
            font-size: medium;
            padding: 11px 18px;
            resize: none;
            border-radius: 10px;
        }

        .editText{
            border-color: colors.$ocean-blue
        }

        .editSelect{
            border: 1px solid colors.$ocean-blue
        }

        .line {
            width: 300px;
        }

        .number {
            display: flex;
            flex-direction: row;
            justify-content: left;

            #numcode {
                width: 100px;
                text-align: center;
                margin-right: 10px;
            }

        }



        .contact {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); // Responsive number of columns
            gap: 20px;
            margin-top: 1rem;

            textarea{
                width: 100%;
            }

            .info{
                h4{
                    color: #767c88;
                     font-family: "ClearSans";   
                     font-weight: 400;
                }
            }

        }

        .languages-edit {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-height: 10rem;
            border-style: solid;
            border-width: 1px;
            border-color: colors.$ocean-blue;
        }

        .languages-edit input[type="checkbox"] {
            display: none;
        }

        .languages-edit label {
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            padding: 1rem;
        }

        .languages-edit input[type="checkbox"]+label::before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            border: 2px solid #ccc;
            border-radius: 4px;
            background-color: white;
            transition: background-color 0.3s, border-color 0.3s;
        }

        .languages-edit input[type="checkbox"]:checked+label::before {
            background-color: colors.$ocean-blue;
            border-color: colors.$ocean-blue;
        }

        .languages-edit input[type="checkbox"]+label::after {
            content: '';
            position: absolute;
            left: 6px;
            top: 2px;
            font-size: 20px;
            text-align: center;
            color: white;
            transition: opacity 0.2s;
            opacity: 0;
            /* Initially hidden */
            padding: 1rem;
        }

        .languages-edit input[type="checkbox"]:checked+label::after {
            content: '\2713';
            /* Checkmark symbol */
            opacity: 1;
            /* Show when checked */
        }

        .languages-display {
            margin-top: 20px;

            .languages-grid {
                display: flex;
                flex-direction: row;
                gap: 10px;
            }

            .language-box {
                padding: 10px 25px;
                border: 1px solid #ccc;
                border-radius: 5px;
                text-align: center;
                background-color: colors.$ocean-blue;
                color: white;
                font-weight: bold;
                max-width: 200px;

            }
        }


    }

    select {
        appearance: none; // Removes default styling
        -webkit-appearance: none;
        -moz-appearance: none;
        background-color: #f0f0f0;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 10px;
        font-size: 16px;
        width: 100%;
        max-width: 200px;
        transition: border-color 0.3s ease;

        &:hover {
            border-color: #888;
        }

        &:focus {
            border-color: colors.$ocean-blue;
            outline: none;
        }

        // Arrow styling
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><polygon points="5,6 10,11 15,6" fill="#333"/></svg>');
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 15px;

        // Disable resize icon in IE
        &::-ms-expand {
            display: none;
        }
    }

    // For older browsers
    select::-webkit-input-placeholder {
        color: transparent;
    }


}

.announcement-page {
    max-width: 800px;
    margin: 0 auto;
    // padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    h1 {
        font-size: 1.8rem;
        color: #333;
        margin-bottom: 20px;
    }

    .announcement-form {
        display: flex;
        flex-direction: column;

        label {
            font-size: 1rem;
            margin-bottom: 8px;
        }

        input,
        textarea {
            font-size: 1rem;
            padding: 10px;
            margin-bottom: 20px;
            border-radius: 4px;
            border: 1px solid #ccc;
        }

        textarea {
            resize: vertical;
            height: 100px;
        }

        button {
            padding: 10px 20px;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            font-size: 1rem;

            &:hover {
                background-color: #0056b3;
            }
        }

        .success-message {
            color: green;
            margin-top: 20px;
            font-size: 1rem;
        }
    }

    .active-announcements {
        margin-top: 50px;

        h2 {
            font-size: 1.5rem;
            color: #333;
            margin-bottom: 20px;
        }

        .announcement-item {
            padding: 15px;
            border-bottom: 1px solid #ccc;
            margin-bottom: 20px;

            h3 {
                font-size: 1.2rem;
                color: #007bff;
                margin-bottom: 10px;
            }

            p {
                font-size: 1rem;
                margin-bottom: 8px;
            }
        }
    }
}

@media (max-width: 1919px) {
    .account-menu {
        min-width: 60vw;
    }
}

@media (max-width: 900px) {
    .account-menu {
        min-width: 80vw;
    }
}


@media (max-width: 599px){
    .account-menu {
        margin-top: 4vh;
        min-width: 90vw;
        width: 90vw;
        .header{
            h1{
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
            }

            .buttons{
                z-index: 1;
            }

            .edit-button{
                width: 9.9rem;
                font-size: 16px;
            }
        }
    }

    .account-menu .account-settings {
        padding: 30px 15px 30px 15px;
    }

    .account-menu .account-settings .languages-display .languages-grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .account-menu .account-settings .languages-display .language-box {
        width: 48%;
    }

    .account-menu .account-settings .contact {
        display: flex;
        flex-direction: column;
    }
} 