@use "../../colors.scss";
@use "../../icons.scss";


$border-radius: 5px;

.head-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: transparent;
    font-weight: bolder;
    font-size: large;
    align-items: center;
    transition: 300ms ease-in;
    span{
        padding-top:12px;
        color:colors.$border;
        transition:300ms
    }
    #previous{
        background-color: colors.$base-white;

    }
    #previous:hover + span{
        color:colors.$ocean-red
    }
}

.announcement-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // gap: 5rem;
    width: 100%;
    min-width: 400px;
    max-width: 1000px;
    margin-top: 12vh;
    margin-bottom: 1rem;
    padding: 1rem;
    min-height: 50vh;
    background-color: colors.$base-white;
    border-radius: $border-radius;
    box-shadow: 5px 4px 4px rgba(0, 0, 0, 0.25);
    background-image: url("../../misc/placeholder/alter-place.png");
    background-size: cover;

    .content-form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5rem;

        .selector-form{
            display: flex;
            gap: 1rem;
        }

        .type.active {
            opacity: 1;
            pointer-events: auto; /* Ativa interação quando selecionada */
        }
          

        .type {
            opacity: 0;
            pointer-events: none; /* Desativa interação inicialmente */
            transition: opacity 0.3s ease-in-out;
            text-align: start;
            z-index: 1;
            width: fit-content;
    
            h2 {
                font-size: 1.3rem;
                margin-bottom: 1rem;
                color: colors.$black;
            }
    
            .selector {
                border: 1px solid colors.$black;
                border-radius: $border-radius;
                padding: 0.5rem;
                width: max-content;
                background-color: colors.$base-white;
                transition: border-color 0.3s;
    
                &:focus-within {
                    border-color: colors.$ocean-blue;
                }
            }
        }
    }

    label {
        font-weight: bold;
    }

    input,
    select,
    textarea,
    .custom-select {
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: $border-radius;
        font-size: 1.2rem;
        transition: border-color 0.3s;
        background-color: colors.$base-white;
        resize: none;
        width: 400px;

       
    }

    .custom-select {
        appearance: none;
        background-color: colors.$base-white;
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: 10px;
        cursor: pointer;

        &:hover {
            border-color: colors.$ocean-blue;
        }
    }

    button {
        padding: 0.5rem 1rem;
        background-color: colors.$ocean-blue;
        color: white;
        border: none;
        border-radius: $border-radius;
        cursor: pointer;
        font-size: 1rem;
        transition: background-color 0.3s;

        &:hover {
            background-color: colors.$ocean-blue;
        }
    }
}

.selector{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    height: 100%;
    gap: 1rem;
}

.modality-selector,
.selected-option {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: 300ms;

    .icon {
        height: 20px;
        width: 20px;
    }

    &:hover {
        span {
            color: colors.$base-white;
        }

        img {
            
            filter: brightness(0) invert(1)
        }
    }
}
.bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    button {
        font-weight: bold;
        transition: 300ms;
        background-color: colors.$ocean-blue;
    }

    #create:hover {
        background-color: colors.$verde-agua;
    }

    #cancel:hover {
        background-color: colors.$ocean-red
    }
}

@media (max-width: 1919px) {
    .announcement-form .content-form {
        gap: 3rem;
    }
    .announcement-form .content-form .selector-form {
        max-width: 100%;
    
        .selector {
            width: 50%;
        }
    }

    .announcement-form .custom-select {
        width: 100%;
    }

    .announcement-form .content-form .type.active {
        width: 50%;
    }
}




@media (max-width: 599px) {
    .announcement-form {
        background-position: center;
    }
    .announcement-form input, .announcement-form select, .announcement-form textarea, .announcement-form .custom-select {
        width: 100%;
    }

    .announcement-form .content-form .selector-form {
        flex-direction: column;
    }
    .announcement-form .content-form .selector-form .selector {
        width: 100%;
    }
    .announcement-form .content-form .type.active {
        width: 100%;
    }
}