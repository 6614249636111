@use "../../colors.scss";
$border: 10px;
$tamanho-maximo: 45rem;

.main{
    height: 90vh;
    background-image: url("../../misc/placeholder/barco-home.png");
    display: flex;
    align-items: center;
    padding: 0 5%;
}

.busca-component {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.form-busca-component {
    display: flex;
    flex-direction: column;
    background-color: colors.$verde-agua-light;
    border-radius: 10px;
    gap: 2rem;
    padding: 4rem 2rem;
    color: colors.$medium-grey;
    max-width: $tamanho-maximo;
    box-sizing: border-box;

    .header{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .bottom,
    .inputs {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}

form {
    .input-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;

        background-color: colors.$base-white;
        border-radius: 5px;
        padding: 0.5rem 1rem;

        input {
            width: 100%;
        }
    }

    button {
        background-color: colors.$ocean-blue;
        color: colors.$base-white;
        padding: 0.75rem 0;
        border-radius: 5px;
    }

    button:hover {
        background-color: colors.$verde-agua;
    }

    .checkbox-container {
        display: flex;
        justify-content: space-between;
        gap: 1rem;

        .checkbox {
            display: flex;
            flex: 1;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;

            background-color: colors.$base-white;
            border-radius: 5px;
            max-width: 35%;
            min-width: fit-content;
            padding: 0.8rem 1rem;

            label {
                line-height: 1rem;
            }

        }
    }
}

@media (max-width: 1919px) {

    .form-busca-component{
        padding: 3rem 2rem;
    }

    .header h1{
        font-size: 26px;
    }
    
}

@media (max-width: 1200px) {

    .form-busca-component{
        border-radius: 5px;
    }

    .form-busca-component{
        padding: 2rem 1rem;
    }

    .header h1{
        font-size: 22px;
    }
}

@media (max-width: 900px) {
    main{
        width: 100vw;
        justify-content: center;
    }

    .header h1{
        font-size: 18px;
    }

    .form-busca-component{
        padding: 1.5rem 2rem;
    }
}

@media (max-width: 599px) {
    .busca-component{
        width: 90vw;
    }
    
    .form-busca-component{
        width: 100%;
        padding: 3rem 1rem;
        gap: 1.5rem;
    }

    .header h1 {
        font-size: 16px;
    }

    .form-busca-component .bottom, .form-busca-component .inputs{
        gap: 1.5rem;
    }

    form .checkbox-container{
        flex-direction: column;
        gap: 1.5rem;
    }

    form .checkbox-container .checkbox{
        max-width: 100%;
    }
}