@use "../../colors.scss";

nav {
  position: fixed;
  width: 100vw;
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: colors.$black;
  background-color: colors.$base-white;
  padding: 0 5%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.267);

  .logo-mar-aberto {
    opacity: 0.5;
    max-width: 100px;
  }

  a.active {
    img {
      opacity: 1;
    }
  }

  .logo-search-container {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    align-items: center;

    .searchbar {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      background-color: colors.$grey;
      padding: 0.5rem 1rem;
      border-radius: 10px;
    }
  }

  .menu-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }

  .menu-items-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3rem;

    .menu-items {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
    }

    .sign {
      a {
        color: colors.$ocean-blue;
        border-radius: 10px;
        padding: 0.5rem 3rem;

        &:hover{
          color:rgba(0, 0, 0, 0.534)
        }
      }
      font-size: x-large;
      font-weight: normal;
    }

    .language-selector{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .burguer-menu {
    display: none;
  }

  .mobile-menu {
    display: none;
    box-sizing: border-box;
    z-index: 1;

    .fechar-button {
      align-self: end;

      img {
        max-width: 20px;
      }
    }
  }
}

@media (max-width: 599px) {
  nav {
    padding: 0.5rem 1rem;
    font-size: 0.85rem;

    .logo-search-container {
      .logo {
        img {
          max-width: 100px;
        }
      }
    }

    .menu-items-container {
      display: none;
    }

    .burguer-menu {
      display: flex;
      align-items: center;
    }

    .mobile-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      padding: 2rem;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      background-color: inherit;

      ul {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        text-align: center;
      }
    }

    .mobile-menu.inactive {
      opacity: 0;
      height: 0px;
      padding: 0;
      transition: 1s ease 0s;

      ul {
        display: none;
      }
    }

    .mobile-menu.active {
      opacity: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      // height: 40vh;
      border-radius: 0.625rem;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05),
        0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      transition: 0.35s ease 0s;
    }
  }
}

.login-button {
  cursor: pointer;
}

.profile-main-button {
  display: flex;
  align-items: center;
  gap: 0.625rem;

  .user-info {
    display: flex;
    flex-direction: column;
    font-size: 14px;

    :first-child {
      color: #000;
    }

    :nth-child(2) {
      color: #767c88;
    }
  }
}

.profile-and-logoff {
  padding: 20px;
  background-color: #fff;
  position: absolute;
  top: 80px;
  border-radius: 10px;
  opacity: 0;
  pointer-events: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;

  a {
    padding: 0 !important;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
}

.profile-main-button:hover .profile-and-logoff {
  opacity: 1;
  pointer-events: all;
}

.function {
  margin-bottom: 200px;
}

.sign {
  .sign-button {
    color: colors.$base-white;
    background-color: colors.$ocean-blue;
    border-radius: 5px;
    padding: 0.5rem 4rem;
    font-size: smaller;
    font-weight: normal;
    &:hover{
      background-color: colors.$verde-agua;
    }
  }
}

@media (max-width: 1919px) {
  nav {
    .sign a {
      font-size: 20px;
    }

    .sign {
      .sign-button {
        padding: 0.5rem 4rem;
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 1200px) {
  nav{

    .menu-items-container{
      gap: 1rem;
    }
    
    .sign a {
      font-size: 15px;
    }
  }
}

@media (max-width: 900px) {
  nav{
    padding: 0.5rem 1rem;
    font-size: 0.85rem;
    width: 100vw;

    .logo-mar-aberto{
      max-width: 70px;
    }

    .logo-search-container{
      gap: .5rem;
    }

    a {
      padding: 0 1rem !important;
    }

    .sign{
      display: flex;
      align-items: center;
      justify-content: center;

      .sign-button{
        padding: 0.5rem 2rem;
        font-size: 15px;
      }
    }
  }
}

@media (max-width: 599px) {
  nav{
    a{
      padding: 0 !important;
    }
  }
}