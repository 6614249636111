.pristine-container{
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 5rem;

    h1{
        font-size: 30px;
        text-align: center;
    }

    div{
        max-width: 250px;
    }
}

@media (max-width: 1919px) {
    .pristine-container{
        padding : 2rem;
        height: 55vh;
        gap: 1rem;
        h1{
            font-size: 22px;
        }
    }

}

@media (max-width: 900px) {
    .pristine-container{
        justify-content: center;
        padding : 2rem;
        height: 60vh;
        gap: 1rem;
        h1{
            font-size: 18px;
        }
    }
    
}

@media (max-width: 599px) {
    .pristine-container{
        justify-content: center;
        padding : 1rem;
        height: 50vh;
        gap: 1rem;
        h1{
            font-size: 16px;
        }
    }
    
}