@use '../../../colors.scss';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;

  h3 {
    padding-bottom: 40px;
  }
}

.modal-actions{
  button{
    margin: 10px;
    padding: 8px 16px;
    font-weight:bold;
    border-radius:5px;
  }
  #confirm {
    background-color: colors.$ocean-red;
    color: white;
    }


}